import { SessionApiImpl } from "@adora/backend-api";
import { AdoraSessionStorageImpl, } from "./adora-session-storage";
import { debugLog } from "./debug";
import { WindowSessionStorage } from "./storage";
export const setupSession = async (sessionType, adoraUserId, settings) => {
    const storage = new AdoraSessionStorageImpl(sessionType, new WindowSessionStorage());
    const api = new SessionApiImpl(settings.backendUrl);
    const result = await startSession(api, settings, adoraUserId);
    if (result.type === "success") {
        storage.setSession(result.storedSession);
    }
    return result;
};
async function startSession(api, settings, adoraUserId) {
    try {
        const startSessionResult = await api.startSession({
            bypassSampling: settings.bypassSampling,
            orgId: settings.orgId,
            language: settings.language || navigator.language,
            domain: new URL(window.location.href).hostname,
            userCohorts: settings.userCohorts || {},
            uid: settings.uid,
            devicePixelRatio: window.devicePixelRatio,
            adoraUserId,
            documentReferrer: document.referrer,
        });
        if (!startSessionResult) {
            return {
                type: "error",
                code: undefined,
            };
        }
        if (startSessionResult.error) {
            return {
                type: "error",
                code: startSessionResult.error.code,
            };
        }
        // We did not get a CREATED, return immediately.
        if (startSessionResult.response.status !== 201) {
            debugLog("session not created, aborting");
            return {
                type: "error",
                code: "code" in startSessionResult.data
                    ? startSessionResult.data.code
                    : undefined,
            };
        }
        if (startSessionResult.data &&
            "scheduledDowntime" in startSessionResult.data) {
            debugLog("scheduled downtime, exiting");
            return {
                type: "error",
                code: undefined,
            };
        }
        if (!startSessionResult.data || !("session" in startSessionResult.data)) {
            debugLog("malformed session, exiting");
            return {
                type: "error",
                code: undefined,
            };
        }
        const { session, organization, eventPostUrl, eventPostToken } = startSessionResult.data;
        const storedSession = {
            id: session.id,
            createdAt: session.createdAt,
            status: session.status,
            lastSyncedAt: session.lastSyncedAt,
            domain: session.domain,
            requestIp: session.requestIp,
            country: session.country,
            userAgent: session.userAgent,
            language: session.language,
            initialUserCohorts: session.initialUserCohorts,
            devicePixelRatio: session.devicePixelRatio,
            organization: organization,
            documentReferrer: session.documentReferrer,
            canvasRecordingEnabled: session.canvasRecordingEnabled || false,
            adoraUserId,
            settings,
            eventPostUrl,
            eventPostToken,
        };
        return {
            type: "success",
            storedSession,
        };
    }
    catch (_e) {
        return {
            type: "error",
            code: undefined,
        };
        // TODO (Arran) - Add sentry logging here
        // Do nothing, did not start session
    }
}
